<template>
  <div class="home_wrap">

    <van-nav-bar
        title="Membro da equipe"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    />


    <div class="total">
      <div>Número de membros：{{total_people}}</div>
      <div>depósito total：{{total_recharge_amount}}</div>
    </div>

    <div class="search-wrap" v-show="true">
      <input class="input-keyword" type="text" v-model="keyword" placeholder="Por favor insira o ID do jogador">
      <van-icon name="search" class="simg"/>
    </div>
    <div class="table_wrap">
      <el-table
          style="border: none!important;background: transparent"
          :data="tableData"
          size="mini"
          :height="'100%'"
          :default-sort = "{prop: 'id', order: 'descending'}"
          :lazy="true"
          :empty-text="'Você não convidou amigos'"
      >
        <el-table-column
            prop="id"
            label="ID"
            align="center"
            sortable


        >
        </el-table-column>
        <el-table-column
            prop="total_bet_amount"
            label="Aposta"
            align="center"
            sortable

        >
        </el-table-column>
        <el-table-column prop="total_recharge_amount" label="Depósito" align="center" sortable show->
        </el-table-column>

      </el-table>
    </div>
    <ft></ft>
  </div>
</template>

<script>
import Vue from 'vue';
import {NavBar, Badge, Swipe, SwipeItem} from 'vant';
import Fetch from '../../utils/fetch';
import ft from "../../components/ft.vue";
import countTo from "vue-count-to";
Vue.use(NavBar).use(Badge);
export default {
  name: "teamList",
  data() {
    return {
      tableData: [],
      tableDataBak: [],
      keyword:"",
      total_people:0,
      total_recharge_amount:0,
    }
  },
  components: {
    ft
  },
  watch:{
    keyword: function (newn) {
      if (newn == "") {
        this.tableData = this.tableDataBak;
        return;
      }
      this.tableData = [];
      this.tableDataBak.forEach(item => {
        if (String(item.id).toLowerCase().search(newn.toLowerCase()) != -1) {
          this.tableData.push(item);
        }
      })
    },
  },
  mounted() {
    this.getSubUsers();
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    getSubUsers(){
      Fetch("/user/subLevelUser").then(r=>{
        this.tableData = r.data;
        this.tableDataBak = r.data;
        this.total_people = r.data.length;
        this.total_recharge_amount = this.tableDataBak.reduce(function (accumulator, currentValue) {
          return parseFloat(accumulator) + parseFloat(currentValue.total_recharge_amount);
        }, 0);
      })
    },
    getSummaries(param) {

      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = 'R$';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    }
  }
}
</script>

<style scoped>
.total{
  width: 95%;
  height: 1rem;
  display: flex;
  margin: 0.1rem auto;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  color: #e5c616;
}
.search-wrap {
  border: 0.1rem solid rgba(255,255,255,0.2);
  height: 0.8rem;
  width: 95%;
  margin: 0.1rem auto;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.simg {
  font-size: 0.6rem;
  margin-right: 0.2rem;
  color: #feb705;
}

.input-keyword {
  width: 70%;
  height: 100%;
  color: white;
  font-size: 0.38rem;
  float: left;
  text-indent: 10px;
  border: none;
  background: transparent;
}
.input-keyword::placeholder{
  color: #fff;
}
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  /*background-color: transparent !important;*/
}
/deep/ .el-table th{
  background-color: #05309f !important;
  border: 0;
}
/deep/ .el-table td{
  border-top: thin solid #303030;
  background-color: #05309f;
  border-bottom: 0;
}
/deep/ .el-table__empty-block{
  background: #05309f;
  border-top: thin solid  #05309f;
  height: auto!important;
}

/deep/.el-table th.el-table__cell.is-leaf {
  border: 0;
}
/deep/ .el-table::before {
  display: none;
}
.el-table thead{
  color: #fff;
}
.txt_center{
  text-align: center;
}
/deep/ .van-nav-bar {
  background: rgb(28, 28, 28);
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.home_wrap {
  width: 100%;
  min-height: 100vh;
  /*background-color: rgb(20 18 18);*/
}
.table_wrap{
  width: 95%;
  margin: 0.5rem  auto;
  border-radius: 0.1rem;
  overflow: hidden;
  height: 80vh;
  /*border: thin solid #ECECEC;*/
}
</style>